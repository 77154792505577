<template>
    <div class='col-12' id='scatterLegendContainer'>
        <div id='scatterLegend'/>
    </div>
</template>

<script>

    import {
        axisBottom,
        interpolateCool,
        interpolateWarm,
        max,
        min,
        scaleLinear,
        scaleOrdinal,
        scaleSequential,
        select
    } from 'd3'
    import colorPalette50 from '../assets/json/colorPalette50.json';

    const CLUSTER_FIELDS = ['rb10_semi', 'rb10_unsv']

    export default {
        name: 'ScatterPlotLegend',
        components: [],
        props: {
            data: Object,
            paint: String
        },
        data () {
            return {}
        },
        mounted () {
            this.render()
        },
        watch: {
            data () {
                this.render()
            },
            paint () {
                this.render()
            }
        },
        methods: {
            render () {

                const WIDTH = 500

                let colorScale
                let domainNumeric
                let domain = []
                this.data.forEach(obj => {
                    domain.push(obj[this.paint])
                })

                // create a color scale for gene expression data if present
                if ('geneExpressionValue' in this.data[0]) {

                    domainNumeric = []
                    this.data.forEach(obj => {
                        domainNumeric.push(obj.geneExpressionValue)
                    })

                    colorScale = scaleSequential().range(['#6495ed','#dc143c'])
                        .domain([min(domainNumeric), max(domainNumeric)])

                } else {

                    domainNumeric = this.convertToNumericDomain(domain)
                    typeof domain[0] === 'number' || !isNaN(domain[0]) ?
                        colorScale = scaleSequential().interpolator(interpolateWarm).domain([min(domain), max(domain)]) :
                        CLUSTER_FIELDS.includes(this.paint) ?
                            colorScale = scaleSequential().interpolator(interpolateCool).domain([min(domainNumeric), max(domainNumeric)]) :
                            colorScale = scaleOrdinal(colorPalette50.palette).domain(domain)
                }

                // clear svg on rerender
                if (document.getElementById('scatterLegend')) {
                    select('#scatterLegend').remove()
                    select('#scatterLegendContainer').append('div')
                        .attr('id', 'scatterLegend')
                }

                // remove legend if paint is not rb10_* or geneExpressionValue is NOT present
                if (!('geneExpressionValue' in this.data[0])) {
                    if (document.getElementById('scatterLegend')) {
                        select('#scatterLegend').style('display', 'none')
                    }
                } else {
                    select('#scatterLegend').style('display', 'block')
                }

                // append a defs (for definition) element to your SVG
                const svg = select('#scatterLegend').append('svg')
                    .attr('preserveAspectRatio', 'xMinYMin meet')
                    .attr('viewBox', '0 0 500 100')
                    .append('g')
                    .attr('transform', 'translate(' + (WIDTH/2-150) + ',' + 0 + ')')
                const defs = svg.append('defs')

                // append a linearGradient element to the defs and give it a unique id
                const linearGradient = defs.append('linearGradient')
                    .attr('id', 'linear-gradient')
                    .attr('x1', '0%')
                    .attr('y1', '0%')
                    .attr('x2', '100%')
                    .attr('y2', '0%')

                // append multiple color stops by using D3's data/enter step
                linearGradient.selectAll('stop')
                    .data([
                        {offset: '0%', color: colorScale(min(domainNumeric)) },
                        {offset: '100%', color: colorScale(max(domainNumeric)) }
                    ])
                    .enter().append('stop')
                    .attr('offset', function(d) {
                        return d.offset
                    })
                    .attr('stop-color', function(d) {
                        return d.color
                    })

                // append title
                svg.append('text')
                    .attr('class', 'legendTitle')
                    .attr('x', 0)
                    .attr('y', 20)
                    .style('text-anchor', 'left')
                    .text('Scatter Plot Legend')

                // draw the rectangle and fill with gradient
                svg.append('rect')
                    .attr('x', 0)
                    .attr('y', 30)
                    .attr('width', 300)
                    .attr('height', 20)
                    .style('fill', 'url(#linear-gradient)')

                //create tick marks
                const xLeg = scaleLinear()
                    .domain([min(domainNumeric), max(domainNumeric)])
                    .range([0, 300])

                const axisLeg = axisBottom(xLeg)

                svg
                    .attr('class', 'axis')
                    .append('g')
                    .attr('transform', 'translate(0, 50)')
                    .call(axisLeg)

            },

            convertToNumericDomain(domain) {

                const unique = domain.filter((val,index, self) => self.indexOf(val) === index)
                unique.sort()

                const domainNumeric = []
                let uniqueNumericMap = {}

                unique.forEach((v, i) => {
                    uniqueNumericMap = { ...uniqueNumericMap, [v]: i+1 }
                })

                domain.forEach(v => {
                    domainNumeric.push(uniqueNumericMap[v])
                })

                this.domainMap = uniqueNumericMap
                return domainNumeric
            }
        }
    }



</script>

<style>

</style>