<template>
    <div :id="'progressionPieChart' + time" style="width: 30%"/>
</template>

<script>

    import {
        arc,
        interpolateCool,
        max,
        min,
        pie,
        scaleSequential,
        select
    } from 'd3'

    export default {
        name: 'Pie',
        props: {
            data: Object,
            time: String,
            key: String,
            uniqueClusters: Array,
            selectedClusterMethod: String
        },
        watch: {
            dataset () {
                this.render()
            },
            data () {
                this.render()
            }
        },
        mounted () {
            this.render()
        },
        methods: {

            render () {

                const formattedData = this.formatData()
                const uniqueClustersNumeric = this.convertClusterListToNumericList()

                const WIDTH = 100
                const HEIGHT = 100
                const MARGIN = 15
                const TOOLTIP_WIDTH = 100

                // radius is set to half the width or half the height, whichever is smaller
                const RADIUS = Math.min(WIDTH, HEIGHT) / 2 - MARGIN

                if (document.getElementById('progressionPieChart' + this.time)) {
                    select('#progressionPieChart' + this.time).remove()
                    select('#pieContainer')
                        .append('div')
                            .attr('id', 'progressionPieChart' + this.time)
                            .style('width', '30%')
                }

                const svg = select('#progressionPieChart' + this.time)
                    .append('svg')
                        .attr('preserveAspectRatio', 'xMinYMin meet')
                        .attr('viewBox', '0 0 100 100')
                    .append('g')
                        .attr('transform', 'translate(' + (WIDTH/2) + ',' + (HEIGHT/2) + ')')

                const color = scaleSequential().interpolator(interpolateCool).domain([min(uniqueClustersNumeric), max(uniqueClustersNumeric)])

                const pieGen = pie().value(d => d[1])
                const dataReady = pieGen(formattedData)

                const arcGenerator = arc()
                    .innerRadius(0)
                    .outerRadius(RADIUS)

                // add tooltip
                const tooltip = select('#progressionPieChart' + this.time)
                    .append('div')
                    .style("opacity", 0)
                    .attr("class", "tooltip")
                    .style("background-color", "white")
                    .style("border", "solid")
                    .style("border-width", "1px")
                    .style("border-radius", "5px")
                    .style("padding", "10px")
                    .style('width', TOOLTIP_WIDTH + 'px')
                    .style('z-index', 10)
                    .style('position', 'absolute')
                    .style('font-size', '0.8em')
                    .style('text-align', 'left')

                svg.selectAll('mySlices')
                    .data(dataReady)
                    .join('path')
                        .attr('d', d => arcGenerator(d))
                        .attr('fill', d => color(this.convertToClusterNumeric(d.data)))
                        .attr('stroke', 'black')
                        .style("stroke-width", "1px")
                        .style("opacity", 0.7)
                    .on('mouseover', () => {
                        tooltip.style('opacity', 1).style('display', 'block')
                    })
                    .on('mousemove', (e,d) => {
                        const innerHtml = '<b>Cluster</b>: ' + d.data[0] + '<br>'
                            + '<b>Proportion</b>: ' + d.value + '%'

                        let clientJustifyX = e.clientX + window.pageXOffset + 15
                        let clientJustifyY = e.clientY + window.pageYOffset - 25

                        tooltip.html(innerHtml)
                            .style('left', clientJustifyX + 'px')
                            .style('top', clientJustifyY + 'px')
                    })
                    .on('mouseleave', () => {
                        tooltip.style('opacity', 0).style('display', 'none')
                    })

                // add percentages to slices
                svg.selectAll('mySlices')
                    .data(dataReady)
                    .join('text')
                    .text(d => {
                        // hide pie chart percentage on small slices for cleaner look
                        if (d.value >= 15)
                            return d.value + '%'
                        else
                            return ''
                    })
                    .attr('transform', d => 'translate(' + arcGenerator.centroid(d) + ')')
                    .style('text-anchor', 'middle')
                    .style('font-size', '0.75em')

                // add chart title
                svg.append('text')
                    .attr('x', -MARGIN+WIDTH/8+5)
                    .attr('y', -(MARGIN)-(WIDTH/4))
                    .attr('text-anchor', 'middle')
                    .style('font-size', '1em')
                    .text(this.time.toUpperCase())

            },

            formatData () {
                const formattedData = []
                let tFormattedData = {}

                for (let i = 0; i < this.data.labels.length; i++) {
                    tFormattedData = { ...tFormattedData, [this.data.labels[i]]: this.data.values[i] }
                }

                Object.keys(tFormattedData).forEach(key => {
                   formattedData.push([key, Math.round(tFormattedData[key]*100)])
                })

                return formattedData
            },

            convertToClusterNumeric (data) {
                return data[0].split(this.selectedClusterMethod)[1]
            },

            convertClusterListToNumericList () {
                const formatted = []
                this.uniqueClusters.forEach(cluster => {
                    formatted.push(Number(cluster.split(this.selectedClusterMethod)[1]))
                })
                formatted.sort()
                return formatted
            }

        }
    }
</script>

<style>

#pieChartContainer {
    width: 100vw;
    height: 100vh;
}

</style>