<template>
    <div>
        <div>
            <h4>Upload New Dataset</h4>
        </div>
        <div id="uploadCohortInputContainer">
            <input type="text" id="uploadCohortInput" :value="cohort" placeholder="Enter Cohort Name..." @keyup="onChangeCohort"/>
        </div>
        <p/>
        <div v-if="cohortError" id="uploadCohortError">
            {{cohortErrorMessage}}
        </div>
        <div v-if="!validCohort" id="uploadCohortError">
            {{errorMessage}}
        </div>
        <p/>
        <div v-if="validCohort" class="row justify-center">
            <q-uploader
                :url="UPLOAD_URL"
                :headers="headers"
                label="Seurat (.rdata)"
                color="teal"
                square
                flat
                bordered
                hide-upload-btn
                accept=".rdata, .Rdata"
                @added="uploadRdataSeuratFile"
                @uploaded="uploadFinishRdataSeuratFile"
                ref="rdataRef"
                style="max-width: 300px;"
            />
            <q-uploader
                :url="UPLOAD_URL"
                :headers="headers"
                label="Leiden Results (.csv)"
                color="teal"
                square
                flat
                bordered
                hide-upload-btn
                accept=".csv"
                @added="uploadCsvLeidenResultsFile"
                @uploaded="uploadFinishCsvLeidenResultsFile"
                ref="csvRef"
                style="max-width: 300px"
            />
        </div>
        <p/>
        <div v-if="validCohort" class="row justify-center">
            <q-uploader
                :url="UPLOAD_URL"
                :headers="headers"
                label="Pathways (.xlsx)"
                field-name="pathways.xlsx"
                color="teal"
                square
                flat
                bordered
                hide-upload-btn
                accept=".xlsx"
                @added="uploadSupplementalPathways"
                @uploaded="uploadFinishPathways"
                ref="pathwaysRef"
                style="max-width: 300px"
            />
            <q-uploader
                :url="UPLOAD_URL"
                :headers="headers"
                label="All Drugs (.xlsx)"
                field-name="alldrugs.xlsx"
                color="teal"
                square
                flat
                bordered
                hide-upload-btn
                accept=".xlsx"
                @added="uploadSupplementalAllDrugs"
                @uploaded="uploadFinishAllDrugs"
                ref="allDrugsRef"
                style="max-width: 300px"
            />
        </div>
        <p/>
        <div v-if="rdataUploadComplete && csvUploadComplete && pathwaysXlsxUploadComplete && allDrugsXlsxUploadComplete" class="row justify-center">
            <div class="upButtonStyle" @click="uploadNewCohort">Upload Another Cohort</div>
        </div>
        <p/>
    </div>
</template>

<script>

    import HttpService from '../services/HttpService'
    import routes from '../assets/json/routes.json'

    export default {
        name: 'Upload',
        data () {
            return {
                rdataFile: null,
                csvFile: null,
                cohort: '',
                validCohort: false,
                cohortError: false,
                errorMessage: '',
                cohortErrorMessage: '',
                headers: [],
                rdataUploadComplete: false,
                csvUploadComplete: false,
                pathwaysXlsxUploadComplete: false,
                allDrugsXlsxUploadComplete: false
            }
        },
        created () {
            this.UPLOAD_URL = process.env.VUE_APP_FLASK_HOST + ':' + process.env.VUE_APP_FLASK_PORT + routes.flask.root + routes.flask.upload
        },
        methods: {
            clearData () {
                this.cohortError = false
                this.validCohort = false
                this.errorMessage = ''
                this.cohortErrorMessage = ''
            },
            onChangeCohort (e) {
                this.cohort = e.target.value
                if (e.key === 'Enter') {

                    const headers = [{ name: 'IscTrack-Cohort', value: null }]

                    this.clearData()
                    this.headers = []

                    if (e.target.value !== null && e.target.value !== undefined && e.target.value !== '') {

                        HttpService.post(routes.server.api.root + routes.server.api.confirmUniqueCohortPost, { cohort: e.target.value })
                            .then(res => { 

                                if (res.data.cohortIsUnique) {
                                    headers[0].value = e.target.value
                                    this.validCohort = true
                                    this.headers = [...headers]
                                } else {
                                    this.cohort = ''
                                    this.validCohort = false
                                    this.errorMessage = res.data.message
                                }
                            })
                    } else {
                        this.cohortError = true
                        this.cohortErrorMessage = 'Please enter a cohort name'
                    }
                }
            },
            uploadRdataSeuratFile () {
                this.$refs.rdataRef.upload()
            },
            uploadCsvLeidenResultsFile () {
                this.$refs.csvRef.upload()
            },
            uploadSupplementalPathways () {
                this.$refs.pathwaysRef.upload()
            },
            uploadSupplementalAllDrugs () {
                this.$refs.allDrugsRef.upload()
            },
            uploadFinishRdataSeuratFile () {
                this.rdataUploadComplete = true
            },
            uploadFinishCsvLeidenResultsFile () {
                this.csvUploadComplete = true
            },
            uploadFinishPathways () {
                this.pathwaysXlsxUploadComplete = true
            },
            uploadFinishAllDrugs () {
                this.allDrugsXlsxUploadComplete = true
            },
            uploadNewCohort () {
                this.clearData()
                this.cohort = ''
                this.rdataUploadComplete = false
                this.csvUploadComplete = false
                this.pathwaysXlsxUploadComplete = false
                this.allDrugsXlsxUploadComplete = false
            }
        }
    }
</script>

<style>

    #uploadCohortInputContainer {
        text-align: center;
    }

    #uploadCohortInput {
        min-width: 12em;
        width: 20vw;
    }

    #uploadCohortError {
        text-align: center;
        color: #fd006a;
        font-style: italic;
    }

    .upButtonStyle {
        width: 20vw;
        text-align: center;
        border: 1px solid #0a9a8d;
        background-color: #0a9a8d;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
    }

    .upYesNoButton {
        border: 1px solid #707070;
        margin-left: 1em;
        margin-right: 1em;
        cursor: pointer;
        padding: 0.2em 0.5em 0.2em 0.5em;
    }

</style>