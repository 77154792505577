<template>
    <div class="col-12" id="missingGenesContainer">
        <div class="row">
            <div class="col-12" id="missingGenesTitle">Missing Genes</div>
        </div>
        <div class="row">
            <div class="col-12" id="missingGenesMessage">Uploaded genes that are not in the selected dataset:</div>
        </div>
        <div class="row">
            <div v-if="missingGenes && missingGenes.length > 1">
                <div class="missingGeneListContainer" v-for="(gene, key) in missingGenes" :key="key">
                    {{gene}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'MissingGenes',
        components: [],
        props: {
            missingGenes: Array
        }
    }

</script>

<style>

    #missingGenesContainer {
        border: 1px solid black;
        padding: 1em;
        margin-top: 1em;
    }

    #missingGenesTitle {
        font-size: 2em;
        font-weight: 700;
        text-align: center;
    }

    #missingGenesMessage {
        margin-bottom: 1.5em;
        text-align: center;
    }

    .missingGeneListContainer {
        width: 30%;
        display:inline-block;
        margin-right: 1em;
    }

</style>