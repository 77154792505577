<template>
    <div v-if="geneList.length > 0 && samples.length > 0" class="col-12" style="margin-left: 1em">
        <div class="row justify-content-center">
            <div class="col-6" id="selectionContainer">
                <q-select clearable color="teal" transition-show="flip-up" transition-duration="500" :dense="true" :options-dense="true" transition-hide="flip-down" v-model="selectedGene" :options="options" label="Select Gene"/>
            </div>
        </div>
        <div id="violinPlotPathwaysContainer" class="col-12">
            <div id="violinPlotPathways"/>
        </div>
    </div>
</template>

<script>

    import HttpService from '../services/HttpService'
    import routes from '../assets/json/routes.json'

    import {
        area,
        axisBottom,
        axisLeft,
        curveCatmullRom,
        group,
        interpolateCool,
        max,
        min,
        scaleBand,
        scaleLinear,
        scaleSequential,
        select
    } from 'd3'

    export default {
        name: 'ViolinDrugPathways',
        components: {
        },
        emits: ['violinDrugPathwaysChange'],
        props: {
            dataset: String,
            selectedClusterMethod: String,
            rawViolinData: Array,
            drugPathwaySelection: String,
            selectedDrugType: String
        },

        data () {
            return {
                firstTimeRender: true,
                selectedGene: null,
                options: [],
                geneList: [],
                samples: []
            }
        },

        watch: {
            dataset () {
                this.selectedGene = null
                this.init()
            },

            selectedGene () {
                this.init()
            },

            selectedClusterMethod () {
                this.init()
            },

            rawViolinData () {
                this.selectedGene = null
                this.options = []
                this.init()
            },

            drugPathwaySelection () {
                this.options = []
                this.resetDomSVG()
            },

            selectedDrugType () {
                this.options = []
                this.resetDomSVG()
            }
        },

        created () {
            this.init()
            this.firstTimeRender = false
        },

        methods: {
            async init () {
                if (this.rawViolinData.length > 0) {
                    this.$emit('violinDrugPathwaysChange', true)
                    const violinData = await this.retrieveData()
                    this.render(violinData)
                    this.$emit('violinDrugPathwaysChange', false)
                }
            },

            async retrieveData () {

                const RAW_VIOLIN_DATA_IGNORE_KEYS = ['barcode']
                const tGeneList = []
                const ALL_HEATMAP_GENES_SELECTED = 'ALL'

                let rnaseqExpScaledClusterPromise
                let leidenMetaPromise

                Object.keys(this.rawViolinData[0]).forEach(key => {
                    if (!RAW_VIOLIN_DATA_IGNORE_KEYS.includes(key)) {
                        tGeneList.push(key)
                    }
                })

                leidenMetaPromise = await HttpService.post(routes.server.api.root + routes.server.api.leidenBarcodesAndClustersPost, { dataset: this.dataset })

                this.geneList = tGeneList
                this.samples = leidenMetaPromise.data.docs
                this.options = [ALL_HEATMAP_GENES_SELECTED, ...tGeneList]

                if (!this.selectedGene || this.selectedGene === ALL_HEATMAP_GENES_SELECTED) {
                    rnaseqExpScaledClusterPromise = await HttpService.post(routes.server.api.root + routes.server.api.violinDataPost, { dataset: this.dataset, geneList: tGeneList, samples: leidenMetaPromise.data.docs })
                } else {
                    rnaseqExpScaledClusterPromise = await HttpService.post(routes.server.api.root + routes.server.api.violinDataPost, { dataset: this.dataset, geneList: [this.selectedGene], samples: leidenMetaPromise.data.docs })
                }

                return { avgScaledExpressions: rnaseqExpScaledClusterPromise.data.avgScaledExpressions, min: rnaseqExpScaledClusterPromise.data.min, max: rnaseqExpScaledClusterPromise.data.max }

            },

            render (data) {

                const CLUSTER_METHOD_PREFIXES = { clusterSemi: 'SS', clusterUnsv: 'US' }
                const CLUSTER_FIELD = { SS: 'clusterSemi', US: 'clusterUnsv' }

                const MARGIN = { top: 0, right: 10, bottom: 35, left: 60 }
                const WIDTH = 450 - MARGIN.left - MARGIN.right
                const HEIGHT = 450 - MARGIN.top - MARGIN.bottom
                let HISTOGRAM_RESOLUTION

                data.avgScaledExpressions.length >= 100 ? HISTOGRAM_RESOLUTION = 10 : HISTOGRAM_RESOLUTION = 5

                this.resetDomSVG()
                /*
                if (document.getElementById('violinPlotPathways')) {
                    select('#violinPlotPathways').remove()
                    select('#violinPlotPathwaysContainer')
                        .append('div')
                            .attr('id', 'violinPlotPathways')
                }

                 */

                const clusterList = []
                data.avgScaledExpressions.forEach(obj => clusterList.push(obj[CLUSTER_FIELD[this.selectedClusterMethod]]) )

                const clusterListNumeric = this.getNumericClusterList(clusterList, CLUSTER_METHOD_PREFIXES, CLUSTER_FIELD)
                clusterList.sort((a, b) => {
                    if (a > b) return 1
                    if (a < b) return -1
                    return 0
                })

                const svg = select('#violinPlotPathways')
                    .append('svg')
                        .attr('preserveAspectRatio', 'xMinYMin meet')
                        .attr('viewBox', '0 0 500 500')
                    .append('g')
                        .attr('transform', 'translate(' + MARGIN.left + ',' + MARGIN.right + ')')

                // x-axis: create scale and add to svg
                const xScale = scaleBand().range([0, WIDTH]).domain(clusterList).padding(0.05)
                svg.append('g').attr("transform", "translate(0," + HEIGHT + ")").call(axisBottom(xScale))

                // y-axis: create scale and add to svg
                const yScale = scaleLinear().domain([data.min, data.max]).range([HEIGHT, 0])
                svg.append('g').call(axisLeft(yScale))


                // compute binning for each group(cluster) of dataset
                const groupings = group(data.avgScaledExpressions, d => d[CLUSTER_FIELD[this.selectedClusterMethod]])
                const sumstat = this.createHistoBins(groupings, HISTOGRAM_RESOLUTION, CLUSTER_METHOD_PREFIXES, CLUSTER_FIELD)
                const areasx0s = this.getAreax0(groupings, HISTOGRAM_RESOLUTION);

                // Find bin with most entries.  Need it because this value will have a width of 100% of the bandwidth.
                let maxNum = 0
                for (let i in sumstat) {
                    const allBins = sumstat[i].value
                    const lengths = allBins.map(a => a.length)
                    const longest = max(lengths)
                    if (longest > maxNum) maxNum = longest
                }

                // The maximum width of a violin must be x.bandwidth = the width dedicated to a group
                const xNum = scaleLinear().range([0, xScale.bandwidth()]).domain([-maxNum, maxNum])

                // color scale
                const colorCluster = scaleSequential().interpolator(interpolateCool).domain([min(clusterListNumeric), max(clusterListNumeric)])

                svg
                    .selectAll("myViolin")
                    .data(sumstat)
                    .enter() // Work cluster by cluster
                    .append("g")
                    .attr("transform", d => "translate(" + xScale(d.key) +" ,0)") // Translation on the right to be at the group position
                    .append("path")
                        .datum(d => {
                            d.value.forEach(a => {
                                a.push(d.key)
                            })
                            return d.value
                        }) // Work bin by bin
                        .style("stroke", "none")
                        .style("fill", (d,i) => colorCluster(d[i][d[i].length - 1].split(CLUSTER_METHOD_PREFIXES[CLUSTER_FIELD[this.selectedClusterMethod]])[1]) )
                        .attr("d", area()
                            .x0(d => xNum(-d.length+1))
                            .x1(d => xNum(d.length-1))
                            .y((d,i) => {
                                const chosenx0 = areasx0s.filter(obj => obj.key === d[d.length-1])[0]
                                return yScale(chosenx0.x0[i])
                            })
                            .curve(curveCatmullRom) // This makes the line smoother to give the violin appearance
                        )

            },

            createHistoBins (groupings, resolution, clusterMethodPrefixes, clusterField) {

                const createBins = (key, dataAry) => {

                    const bins = []
                    for (let i = 0; i < resolution; i++) bins.push([])

                    let min = 0
                    let max = 0
                    let stepSize

                    dataAry.forEach(obj => {
                        if (obj.avgScaledExp > max) {
                            max = obj.avgScaledExp
                        }
                        if (obj.avgScaledExp < min) {
                            min = obj.avgScaledExp
                        }
                    })

                    stepSize = (max - min) / resolution

                    dataAry.forEach(obj => {
                        for (let i = 0; i < resolution; i++) {
                            if (i === 0) {
                                if (obj.avgScaledExp >= min && obj.avgScaledExp <= (min + stepSize)) {
                                    bins[i].push(obj.avgScaledExp)
                                    continue
                                }
                            } else {

                                if ( (obj.avgScaledExp > (i * stepSize + min)) && (obj.avgScaledExp <= ((i+1) * stepSize + min)) ) {
                                    bins[i].push(obj.avgScaledExp)
                                    continue
                                }

                            }
                        }
                    })

                    return { key, value: bins }

                }

                const binnedData = []

                groupings.forEach((val, key) => {
                    binnedData.push(createBins(key, val))
                })

                const prefix = clusterMethodPrefixes[clusterField[this.selectedClusterMethod]]

                binnedData.sort((a, b) => {
                    const aClusterNum = a.key.split(prefix)[1]
                    const bClusterNum = b.key.split(prefix)[1]

                    if (aClusterNum > bClusterNum) return 1
                    if (aClusterNum < bClusterNum) return -1
                    return 0
                })

                return binnedData
            },

            // step function for d3.area(), required to access d.x0 since d3 v7 does not append this to 'd' automatically
            getAreax0 (groupings, resolution) {

                const tAreasx0s = []

                groupings.forEach((val, key) => {

                    let min = 0
                    let max = 0

                    val.forEach(obj => {
                        if (obj.avgScaledExp < min) { min = obj.avgScaledExp }
                        if (obj.avgScaledExp > max) { max = obj.avgScaledExp }
                    })

                    const stepSize = (max - min) / resolution
                    const steps = [min]
                    let counter = min

                    for (let i = 0; i < resolution; i++) {
                        counter += stepSize
                        steps.push(counter)
                    }

                    tAreasx0s.push({ key, x0: steps })

                })

                return tAreasx0s
            },

            getNumericClusterList(clusterList, clusterMethodPrefixes, clusterField) {
                const numericList = []
                clusterList.forEach(val => {
                    numericList.push(val.split(clusterMethodPrefixes[clusterField[this.selectedClusterMethod]])[1])
                })
                return numericList
            },

            resetDomSVG () {
                if (document.getElementById('violinPlotPathways')) {
                    select('#violinPlotPathways').remove()
                    select('#violinPlotPathwaysContainer')
                        .append('div')
                        .attr('id', 'violinPlotPathways')
                }
            }

        }
    }
</script>

<style>

    #selectionContainer {
        max-width: 400px;
        overflow-x: hidden;
        margin-bottom: 1em;
    }

</style>