<template>
    <div>
        <div class="" id="selectionContainer">
            <q-select
                clearable
                multiple
                emit-value
                color="teal" 
                use-input
                input-debounce="300"
                transition-show="flip-up" 
                transition-duration="500" 
                :dense="true" 
                :options-dense="true" 
                transition-hide="flip-down"
                v-model="selectedGene"
                use-chips
                label="Gene List"
                :options="options"
                @filter="getGeneList"
                
            >
                <template v-slot:no-option>
                    <q-item>
                        <q-item-section class="text-grey">
                            No results
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>
        </div>
    </div>
</template>

<script>
    
    import HttpService from '../services/HttpService'
    import routes from '../assets/json/routes.json'

    export default {
        name: 'GeneExpressionSelector',
        emits: ['selectedGeneChange'],
        props: {
            dataset: String,
            override: Boolean
        },
        data () {
            return {
                options: null,
                selectedGene: null,
                geneList: null
            }
        },
        watch: {
            selectedGene (nval) {
                let gene;
                nval ? gene = nval : gene = ''
                this.$emit('selectedGeneChange', gene)
            },

            override (nval, oval) {
                if (nval !== oval) {
                    this.selectedGene = null
                }
            }
        },
        methods: {

            getGeneList (val, update) {
                const options = []
                if (!val && !this.geneList) {
                    HttpService.post(routes.server.api.root + routes.server.api.geneListGet, { dataset: this.dataset })
                        .then(res => {

                            Object.keys(res.data.geneListRaw).forEach(key => {
                                options.push({ label: key, value: key })
                            })

                            this.geneList = options

                            update(() => {
                                this.options = options
                            })
                        })
                } else if (!val && this.geneList) {
                    update(() => {
                        this.options = this.geneList
                    })
                } else {
                    const searchTerm = val.toLowerCase()
                    const searchValues = this.geneList.filter(v => v.value.toLowerCase().indexOf(searchTerm) > -1)
                    update(() => {
                        this.options = searchValues
                    })
                }
            }

        }
    }
</script>

<style>

    #selectionContainer {
        max-width: 400px;
        overflow-x: hidden;
    }

</style>