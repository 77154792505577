<template>
    <div id="pieLegend" class="col-12"/>
</template>

<script>

    import {
        interpolateCool,
        max,
        min,
        scaleSequential,
        select
    } from 'd3'

    export default {
        name: 'PieLegend',
        props: {
            uniqueClusters: Array,
            selectedClusterMethod: String
        },
        watch: {
            uniqueClusters () {
                this.render()
            }
        },
        mounted () {
            this.render()
        },
        methods: {

            render () {
                const uniqueClustersNumerical = this.getClusterArrayNumeric()

                const WIDTH = 500

                if (document.getElementById('pieLegend')) {
                    select('#pieLegend').remove()
                    select('#pieLegendContainer')
                        .append('div')
                            .attr('id', 'pieLegend')
                            .attr('class', 'col-12')
                }

                const svg = select('#pieLegend')
                    .append('svg')
                        .attr('preserveAspectRatio', 'xMinYMin meet')
                        .attr('viewBox', '0 0 500 500')
                    .append('g')
                        .attr('transform', 'translate(' + (WIDTH/2-50) + ',' + 0 + ')')

                const color = scaleSequential().interpolator(interpolateCool).domain([min(uniqueClustersNumerical), max(uniqueClustersNumerical)])
                
                svg.selectAll('legendDots')
                    .data(uniqueClustersNumerical)
                    .enter()
                    .append('circle')
                        .attr('cx', 10)
                        .attr('cy', (d,i) => { return 20 + i * 25 })
                        .attr('r', 7)
                        .style('fill', d => color(d))

                svg.selectAll('pieLegendLabels')
                    .data(uniqueClustersNumerical)
                    .enter()
                    .append('text')
                        .attr('x', 25)
                        .attr('y', (d,i) => { return 25 + i * 25 })
                        .style('fill', '#000')
                        .text(d => this.selectedClusterMethod + d)
                        .attr('text-anchor', 'left')
                        .attr('alignment-baseline', 'middle')

            },

            getClusterArrayNumeric () {
                const formatted = []
                this.uniqueClusters.forEach(cluster => {
                    formatted.push(cluster.split(this.selectedClusterMethod)[1])
                })
                formatted.sort()
                return formatted
            }
        }
    }
</script>

<style>

</style>