<template>
    <div>
        <div class="" id="selectionContainer">
            <q-select clearable color="teal" transition-show="flip-up" transition-duration="500" :dense="true" :options-dense="true" transition-hide="flip-down" v-model="selectedOption" :options="options" label="Select"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AnnotationSelector',
        emits: ['selectedAnnotationOptionChange'],
        props: {
            options: Array
        },
        data () {
            return {
                selectedOption: null
            }
        },
        watch: {
            selectedOption (nval) {
                this.$emit('selectedAnnotationOptionChange', nval)
            }
        }
    }
</script>

<style>

    #selectionContainer {
        max-width: 400px;
        overflow-x: hidden;
    }

</style>