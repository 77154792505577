<template>
    <div v-if="drugPathwaySelection === DRUG_PATHWAY_SELECTION.drug" id="selectionContainer">
        <q-select clearable color="teal" transition-show="flip-up" transition-duration="500" :dense="true" :options-dense="true" transition-hide="flip-down" v-model="selectedDrugType" :options="drugTypeList" label="Select"/>
    </div>
</template>

<script>

    import HttpService from '../services/HttpService'
    import routes from '../assets/json/routes.json'

    const DRUG_PATHWAY_SELECTION = { drug: 'drug', pathways: 'pathways'}

    export default {
        name: 'DrugTypeSelector',
        emits: ['selectedDrugTypeChange'],
        props: {
            dataset: String,
            drugPathwaySelection: String,
        },
        data () {
            return {
                drugTypeList: [],
                selectedDrugType: ''
            }
        },
        watch: {
            selectedDrugType (nVal) {
                this.$emit('selectedDrugTypeChange', nVal)
            }
        },
        created() {

            this.DRUG_PATHWAY_SELECTION = DRUG_PATHWAY_SELECTION

            HttpService.get(routes.server.api.root + routes.server.api.drugTypeListGet)
                .then(res => {

                    const formattedDrugTypeList = []
                    let formattedWord = null

                    res.data.drugList.forEach(drugType => {
                        formattedWord = drugType.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).join(' ')
                        formattedWord = formattedWord.charAt(0).toUpperCase() + formattedWord.slice(1)
                        formattedDrugTypeList.push(formattedWord)
                    });

                    this.selectedDrugType = formattedDrugTypeList[0]
                    this.drugTypeList = formattedDrugTypeList
                })

        }
    }
</script>

<style>

    #selectionContainer {
        max-width: 400px;
        overflow-x: hidden;
    }

</style>