import axios from 'axios'

class HttpService {

    constructor () {
        this.baseUrl = process.env.VUE_APP_NODE_HOST + ':' + process.env.VUE_APP_NODE_PORT
    }

    get (url) {
        return axios.get(this.baseUrl + url)
    }

    post (url, body) {
        return axios.post(this.baseUrl + url, body)
    }

}

export default new HttpService()