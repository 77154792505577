<template>
  <div>
    <div class="row justify-center">
      <div class="col-4"><TsneUmapSelector @tsneOrUmapChange="onChangeTsneOrUmap"/></div>
      <div class="col-4"/>
      <div class="col-4"><DrugPathwaySelector @drugPathwaySelectionChange="onDrugPathwaySelectionChange"/></div>
    </div>
    <div class="row jusitfy-center">
      <div class="col-4"><GeneSetUploader :dataset="selectedDataset" @uploadedGeneListChange="onChangeUploadedGeneList" :uploadedGeneListOverride="uploadedGeneListOverride"/></div>
      <div class="col-4"/>
      <div class="col-4"/>
    </div>
    <div class="row justify-center exploreWindow">
      <div class="col-12 c1">
        <ExploreContainer
          containerNumber="1"
          plotTitle=""
          :scatterPlotData="scatterPlotData"
          :dataset="selectedDataset"
          :drugPathwaySelection="drugPathwaySelection"
          :uploadedGeneList="uploadedGeneList"
          @uploadedGeneListOverrideChange="onUploadedGeneListOverrideChange"
        />
      </div>
    </div>
  </div>
</template>

<script>

  import ExploreContainer from '../components/ExploreContainer'
  import TsneUmapSelector from '../components/TsneUmapSelector'
  import DrugPathwaySelector from '../components/DrugPathwaySelector'
  import GeneSetUploader from '../components/GeneSetUploader'
  import HttpService from '../services/HttpService'
  import routes from '../assets/json/routes.json'

  const TSNE_OR_UMAP = { tsne: 'tsne', umap: 'umap' }
  const DRUG_PATHWAY_SELECTION = { drug: 'drug', pathways: 'pathways'}

  export default {
    name: 'Explore',
    components: {
      ExploreContainer,
      TsneUmapSelector,
      DrugPathwaySelector,
      GeneSetUploader
    },
    data () {
      return {
        selectedDataset: null,
        scatterPlotData: null,
        drugPathwaySelection: DRUG_PATHWAY_SELECTION.drug,
        uploadedGeneList: [],
        uploadedGeneListOverride: false
      }
    },
    created() {
      const dataset = this.$route.params.dataset
      this.selectedDataset = dataset
      this.getData(dataset)
    },
    methods: {
      onChangeTsneOrUmap (nval) {
        this.getData(this.selectedDataset, nval)
      },

      async getData (dataset, tsneOrUmap) {

        if (tsneOrUmap === null || tsneOrUmap === undefined || tsneOrUmap === '') {
          tsneOrUmap = TSNE_OR_UMAP.tsne
        }

        let url = routes.server.api.root;
        tsneOrUmap === TSNE_OR_UMAP.tsne ? url += routes.server.api.tsneAllGet : url += routes.server.api.umapAllGet

        const leidenRes = await HttpService.post(routes.server.api.root + routes.server.api.leidenPredAllGet, { dataset })
        const scatterPlotDataRes = await HttpService.post(url, { dataset })

        const data = [];

        leidenRes.data.docs.forEach(leidObj => {
          scatterPlotDataRes.data.docs.forEach(scatterObj => {

            if (leidObj.barcode === scatterObj.barcode) {
              data.push({ ...leidObj, component_1: scatterObj.component_1, component_2: scatterObj.component_2 })
            }

          })
        })

        this.scatterPlotData = data

      },

      onDrugPathwaySelectionChange (drugPathwaySelection) {
        this.drugPathwaySelection = drugPathwaySelection
      },

      onChangeUploadedGeneList (genes) {
          this.uploadedGeneList = genes
      },

      onUploadedGeneListOverrideChange () {
          this.uploadedGeneListOverride = !this.uploadedGeneListOverride
      }

    }
  }
</script>

<style>

  .exploreWindow {
    height: 90vh;
    margin: auto;
  }

  .c1 {
    text-align: center;
  }

  .c2 {
    text-align: center;
  }

</style>