import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Explore from '../views/Explore'
import Upload from '../views/Upload'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/explore/:dataset',
    name: 'Explore',
    component: Explore
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
