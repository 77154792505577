<template>
    <div class="q-pa-md">
        <div class="q-gutter-sm">
            <q-radio keep-color v-model="tsneOrUmapSelection" :val="DATA_TYPES.tsne" color="teal" label="TSNE" size="2.5em" />
            <q-radio keep-color v-model="tsneOrUmapSelection" :val="DATA_TYPES.umap" color="pink" label="UMAP" size="2.5em" />
        </div>
    </div>
</template>

<script>

    const DATA_TYPES = { tsne: 'tsne', umap: 'umap' }

    export default {
        name: 'TsneUmapSelector',
        emits: ['tsneOrUmapChange'],
        watch: {
            tsneOrUmapSelection (nval) {
                this.$emit('tsneOrUmapChange', nval)
            }
        },
        created () {
            this.DATA_TYPES = DATA_TYPES
        },
        mounted () {
            this.$emit('tsneOrUmapChange', DATA_TYPES.tsne)
        },
        data () {
            return {
                tsneOrUmapSelection: DATA_TYPES.tsne
            }
        }
    }
</script>

<style>

</style>