<template>
    <div v-if="showNavbar">
        <router-link to="/" class="routerLinkCstm">Home</router-link>
        <router-link to="/upload" class="routerLinkCstm">Upload</router-link>
    </div>
</template>

<script>
    export default {
        name: 'Navbar',
        data () {
            return {
                showNavbar: false
            }
        },
        watch: {
            $route (n) {
                if (n.path === '/' || n.path === '/home') {
                    this.showNavbar = false
                } else {
                    this.showNavbar = true
                }
            }
        }
    }
</script>

<style>

    .routerLinkCstm, a.routerLinkCstm {
        font-size: 1em;
        text-decoration: none;
        margin-right: 1em;
        color: #2c3e50;
    }

</style>