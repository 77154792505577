<template>
    <div style="margin-top: 1.5em">
        <AgGridVue
            class="ag-theme-alpine agGridExploreContaner"
            rowSelection='single'
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @rowClicked="onRowClick"
        />
    </div>
</template>

<script>

    import { AgGridVue } from 'ag-grid-vue3';
    import HttpService from '../services/HttpService'
    import routes from '../assets/json/routes.json'

    export default {
        name: 'DrugPredictionTable',
        emits: ['drugOrPathwaysGeneExpressionsChange', 'drugOrPathwaysGeneExpressionLoading'],
        components: {
            AgGridVue
        },
        props: {
            dataset: String,
            drugType: String
        },
        data () {
            return {
                gridOptions: null,
                gridApi: null,
                columnDefs: [],
                rowData: [],
                selectedDrug: '',
                selectedGeneList: []
            }
        },
        created () {
            this.updateGridData()
            this.gridOptions = {};
        },
        mounted() {
            this.gridApi = this.gridOptions.api;
            this.gridColumnApi = this.gridOptions.columnApi;
        },
        watch: {
            drugType () {
                this.updateGridData()
            }
        },
        methods: {

            updateGridData () {

                const FIELDS_TO_INCLUDE_IN_TABLE = ['annot_id', 'annot_coef', 'annot_var', 'pvalue_louis', 'pvalue', 'gene_ls', 'num_gene', 'up_gene_prop']
                const STRING_HEADERS = ['annot_id', 'gene_ls', 'drugType', 'dataset']

                HttpService.post(routes.server.api.root + routes.server.api.drugPredictionsPost, { dataset: this.dataset, drugType: this.drugType })
                .then(res => {

                    const tColumnDefs = []
                    let headerName

                    Object.keys(res.data.docs[0]).forEach(key => {

                        headerName = this.createReadableHeader(key)

                        if (FIELDS_TO_INCLUDE_IN_TABLE.includes(key)) {
                            if (key === 'gene_ls') {
                                tColumnDefs.push({
                                    headerName,
                                    field: key,
                                    filter: 'agTextColumnFilter',
                                    resizable: true,
                                    valueFormatter: this.textFormatter,
                                    wrapText: true,
                                    autoHeight: true
                                })
                            } else if (STRING_HEADERS.includes(key)) {
                                tColumnDefs.push({
                                    headerName,
                                    field: key,
                                    filter: 'agTextColumnFilter',
                                    resizable: true,
                                    valueFormatter: this.textFormatter
                                })
                            } else {
                                tColumnDefs.push({
                                    headerName,
                                    field: key,
                                    filter: 'agNumberColumnFilter',
                                    resizable: true,
                                    valueFormatter: this.numberFormatter,
                                })
                            }
                        }
                    })

                    this.rowData = res.data.docs
                    this.columnDefs = tColumnDefs

                })

            },

            /*
            onFirstDataRendered (params) {
                const firstRowData = params.columnApi.columnController.rowModel.rowsToDisplay[0].data
                    this.selectedDrug = firstRowData.annot_id
                    this.selectedGeneList = firstRowData.gene_ls

                    HttpService.post(routes.server.api.root + routes.server.api.drugOrPathwaySelectedGeneExpressionPost, { dataset: this.dataset, geneList: this.selectedGeneList })
                    .then(res => {
                        this.$emit('drugOrPathwaysGeneExpressionsChange', res.data.docs)
                    })
            },
            */

            onGridReady () { },

            onRowClick (params) {
                this.$emit('drugOrPathwaysGeneExpressionLoading', true)
                this.selectedDrug = params.data.annot_id
                this.selectedGeneList = params.data.gene_ls

                HttpService.post(routes.server.api.root + routes.server.api.drugOrPathwaySelectedGeneExpressionPost, { dataset: this.dataset, geneList: this.selectedGeneList })
                    .then(res => {
                        this.$emit('drugOrPathwaysGeneExpressionsChange', res.data.docs)
                    })

            },

            textFormatter (params) {
                if (params.colDef.field !== 'gene_ls') {
                    return params.value.toUpperCase()
                }
            },

            numberFormatter (params) {
                if (params.colDef.field === 'num_gene') {
                    return params.value.toFixed(0)
                }
                if (params.value <= -0.005 || params.value >= 0.005) {
                    return params.value.toFixed(2)
                }
                return params.value.toExponential(2)
            },

            createReadableHeader (key) {
                switch (key) {
                    case 'annot_id':
                        return 'ID'

                    case 'annot_coef':
                        return 'Beta'

                    case 'annot_var':
                        return 'Var(beta)'

                    case 'pvalue_louis':
                        return 'P-value Louis'

                    case 'pvalue':
                        return 'P-value'

                    case 'gene_ls':
                        return 'Genes'

                    case 'num_gene':
                        return 'Number of Genes'

                    case 'up_gene_prop':
                        return 'Up regulation (%)'

                    default:
                        return key
                }
            }
        }
    }
</script>

<style>

div > div.ag-call {
    font-size: 0.5em;
}

.ag-cell-wrap-text {
    word-break: keep-all;
}

</style>