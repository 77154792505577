<template>
    <div class="row" id="devBanner">
        <div class="col-3">DEVELOPMENT</div>
        <div class="col-3">DEVELOPMENT</div>
        <div class="col-3">DEVELOPMENT</div>
        <div class="col-3">DEVELOPMENT</div>
    </div>
</template>

<script>
    export default {
        name: 'DevelopmentBanner'
    }
</script>

<style>

    #devBanner {
        background-color: #ff004c;
        color: #fff;
        font-weight: 600;
        text-align: center;
    }

</style>