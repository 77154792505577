<template>
    <div v-if="scatterLoading || drugOrPathwaysLoading || violinPlotLoading || violinDrugPathwaysLoading" class="loader"></div>
</template>

<script>
    export default {
        name: 'Loader',
        props: {
            scatterLoading: Boolean,
            drugOrPathwaysLoading: Boolean,
            violinPlotLoading: Boolean,
            violinDrugPathwaysLoading: Boolean
        }
    }
</script>

<style>

    .loader {
        margin-left: 50%;
        border: 16px solid #dbdbdb;
        border-radius: 50%;
        border-top: 16px solid #3d3d3d;
        width: 0.5em;
        height: 0.5em;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>