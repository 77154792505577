<template>
    <div>
        <div class="" id="selectionContainer">
            <q-select clearable color="teal" transition-show="flip-up" transition-duration="500" :dense="true" :options-dense="true" transition-hide="flip-down" v-model="selectedOption" :options="options" label="Paint"/>
        </div>
    </div>
</template>

<script>

    import Rb10Converter from '../services/Rb10Converter'

    const DEFAULT_SELECTION = 'semi-supervised'

    export default {
        name: 'PaintSelector',
        props: {
            options: Array,
            override: Boolean
        },
        emits: ['selectedPaintOptionChange'],
        data () {
            return {
                selectedOption: DEFAULT_SELECTION
            }
        },
        created() {
            this.$emit('selectedPaintOptionChange', Rb10Converter.longToRb10(DEFAULT_SELECTION))
        },
        watch: {
            selectedOption (nval) {
                this.$emit('selectedPaintOptionChange', Rb10Converter.longToRb10(nval))
            },

            override (nval, oval) {
                if (nval !== oval) {
                    this.selectedOption = null
                }
            }
        }
    }
</script>

<style>

    #selectionContainer {
        max-width: 400px;
        overflow-x: hidden;
    }

</style>