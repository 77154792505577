<template>
    <div id="heatmapContainer" class="col-12">
        <div id="biomarkersDownload">
            <a :href="BIOMARKERS_CSV_DOWNLOAD_URL">Download Biomarkers</a>
        </div>
        <div id="clusters"/>
        <div id="heatmap"/>
    </div>
</template>

<script>

    import HttpService from '../services/HttpService'
    import routes from '../assets/json/routes.json'
    import {
        //axisBottom,
        axisLeft,
        interpolateBlues,
        interpolateCool,
        max,
        min,
        scaleBand,
        scaleSequential,
        select,
    } from 'd3'

    const CLUSTER_METHOD_PREFIXES = { clusterSemi: 'SS', clusterUnsv: 'US' }

    const MARGIN = { top: 0, right: 10, bottom: -10, left: 60 }
    const WIDTH = 450 - MARGIN.left - MARGIN.right
    const HEIGHT = 450 - MARGIN.top - MARGIN.bottom
    const MARGIN_CLUSTERS = { top: 0, right: MARGIN.right, bottom: 0, left: MARGIN.left }
    const TOOLTIP_WIDTH = 150
    const TOOLTIP_CLUSTER_WIDTH = 75

    export default {
        name: 'Heatmap',
        props: {
            dataset: String,
            selectedClusterMethod: String
        },
        data () {
            return {
                transformedHeatmapData: [],
                clusters: [],
                BIOMARKERS_CSV_DOWNLOAD_URL: null
            }
        },
        created () {
            this.updateHeatmap()
            this.BIOMARKERS_CSV_DOWNLOAD_URL = process.env.VUE_APP_PROXY_HOST + ':' + process.env.VUE_APP_PROXY_PORT + '/' + process.env.VUE_APP_PROXY_PREFIX + '/' + this.dataset + '/output/' + process.env.VUE_APP_BIOMARKERS_CSV_SS
        },
        watch: {
            transformedHeatmapData (nval) {
                this.render(nval)
            },
            selectedClusterMethod (nval) {

                this.updateHeatmap()

                if (nval === CLUSTER_METHOD_PREFIXES.clusterUnsv) {
                    this.BIOMARKERS_CSV_DOWNLOAD_URL = process.env.VUE_APP_PROXY_HOST + ':' + process.env.VUE_APP_PROXY_PORT + '/' + process.env.VUE_APP_PROXY_PREFIX + '/' + this.dataset + '/output/' + process.env.VUE_APP_BIOMARKERS_CSV_UNSV
                } else {
                    this.BIOMARKERS_CSV_DOWNLOAD_URL = process.env.VUE_APP_PROXY_HOST + ':' + process.env.VUE_APP_PROXY_PORT + '/' + process.env.VUE_APP_PROXY_PREFIX + '/' + this.dataset + '/output/' + process.env.VUE_APP_BIOMARKERS_CSV_SS
                }
            }
        },
        methods: {
            updateHeatmap () {

                let rawHeatmapData;

                HttpService.post(routes.server.api.root + routes.server.api.heatmapDataPost, { dataset: this.dataset, clusterMethod: this.selectedClusterMethod })
                    .then(res => {
                        this.clusters = this.getClusterGroupings(res.data.docs)
                        rawHeatmapData = res.data.docs
                        this.transformedHeatmapData = this.transformForHeatmap(rawHeatmapData)
                    })
            },

            transformForHeatmap (data) {

                let uniqueSamplesAry = [];
                let genes = []
                let samples = []
                let values = []

                data[0].samples.forEach(obj => {
                    uniqueSamplesAry.push(obj.id)
                })

                uniqueSamplesAry.forEach(id => {
                    for (let i = 0; i < data.length; i++) {
                        genes.push(data[i].gene)
                        for (let j = 0; j < data[i].samples.length; j++) {
                            if (data[i].samples[j].id === id) {
                                samples.push(id)
                                values.push(data[i].samples[j].val)
                                break;
                            }
                        }
                    }
                });

                return { genes, samples, values }
            },

            getClusterGroupings (data) {
                const firstRow = data.filter(obj => obj.rowOrder === 0)
                const clusters = { xScale: [], colorScale: [] }

                firstRow[0].samples.forEach(obj => {
                    clusters.colorScale.push(Number(obj.id.match(/\d+/g)[0]))
                    clusters.xScale.push(obj.id)
                })

                return clusters
            },

            render (data) {

                if (document.getElementById('clusters')) {
                    select('#clusters').remove()
                    select('#heatmapContainer')
                        .append('div')
                            .attr('id', 'clusters')
                }

                if (document.getElementById('heatmap')) {
                    select('#heatmap').remove()
                    select('#heatmapContainer')
                        .append('div')
                            .attr('id', 'heatmap')
                }

                const svg = select('#heatmap')
                    .append('svg')
                        .attr('preserveAspectRatio', 'xMinYMin meet')
                        .attr('viewBox', '0 0 450 500')
                    .append('g')
                        .attr('transform', 'translate(' + MARGIN.left + ',' + MARGIN.top + ')')

                const xScale = scaleBand().range([0,WIDTH]).domain(data.samples).padding(0.05)
                const yScale = scaleBand().range([HEIGHT,0]).domain(data.genes).padding(0.05)

                // format x-axis
                /*
                svg.append('g')
                    .style('font-size', '10px')
                    .attr('transform', 'translate(0,' + HEIGHT + ')')
                    .call(axisBottom(xScale).tickSize(0))
                    .select('.domain').remove()
                */

                // format y-axis
                svg.append('g')
                    .style('font-size', '10px')
                    .call(axisLeft(yScale).tickSize(0))
                    .select('.domain').remove()

                const color = scaleSequential().interpolator(interpolateBlues).domain([min(data.values), max(data.values)])

                // add tooltip
                const tooltip = select('#heatmap')
                    .append('div')
                    .style('opacity', 0)
                    .attr('class', 'tooltip')
                    .style("background-color", "white")
                    .style("border", "solid")
                    .style("border-width", "1px")
                    .style("border-radius", "5px")
                    .style("padding", "10px")
                    .style('width', TOOLTIP_WIDTH + 'px')
                    .style('z-index', 10)
                    .style('position', 'absolute')
                    .style('font-size', '0.8em')
                    .style('text-align', 'left')

                // add squares
                const dataFormattedD3 = []
                for (let i = 0; i < data.samples.length; i++) {
                    dataFormattedD3.push({ gene: data.genes[i], sample: data.samples[i], value: data.values[i] })
                }

                svg.selectAll()
                    .data(dataFormattedD3)
                    .enter()
                    .append('rect')
                        .attr('x', d => xScale(d.sample))
                        .attr('y', d => yScale(d.gene))
                        .attr('rx', 0)
                        .attr('ry', 0)
                        .attr('width', xScale.bandwidth())
                        .attr('height', yScale.bandwidth())
                        .style('fill', d => color(d.value))
                        .style('stroke-width', 4)
                        .style('stroke', 'none')
                        .style('opacity', 1)
                    .on('mouseover', function () {
                        tooltip.style('opacity', 1).style('display', 'block')
                    })
                    .on('mousemove', function (e, d) {
                        const innerHtml = '<b>Cluster</b>: ' + d.sample.split('_')[0] + '<br>'
                            + '<b>Gene</b>: ' + d.gene + '<br>'
                            + '<b>Value</b>: ' + d.value + '<br>'

                        let clientJustifyX = e.clientX + window.pageXOffset + 10
                        let clientJusitfyY = e.clientY + window.pageYOffset

                        tooltip.html(innerHtml)
                            .style('left', clientJustifyX + 'px')
                            .style('top', clientJusitfyY + 'px')
                    })
                    .on('mouseleave', function () {
                        tooltip
                            .style('opacity', 0)
                            .style('display', 'none')
                    })

                /************************/
                // add clusters bar
                /************************/
                const svg2 = select('#clusters')
                    .append('svg')
                        .attr('preserveAspectRatio', 'xMinYMin meet')
                        .attr('viewBox', '0 0 450 10')
                    .append('g')
                        .attr('transorm', 'translate(' + MARGIN_CLUSTERS.left +  ',' + MARGIN_CLUSTERS.top + ')')

                const xScaleCluster = scaleBand().range([0,WIDTH]).domain(this.clusters.xScale).padding(0.05)
                const yScaleCluster = scaleBand().range([10,0]).padding(0.05)

                const colorCluster = scaleSequential().interpolator(interpolateCool).domain([min(this.clusters.colorScale), max(this.clusters.colorScale)])

                const tooltipCluster = select('#clusters')
                    .append('div')
                    .style('opacity', 0)
                    .attr('class', 'tooltip')
                    .style("background-color", "white")
                    .style("border", "solid")
                    .style("border-width", "1px")
                    .style("border-radius", "5px")
                    .style("padding", "10px")
                    .style('width', TOOLTIP_CLUSTER_WIDTH + 'px')
                    .style('z-index', 10)
                    .style('position', 'absolute')
                    .style('font-size', '0.8em')
                    .style('text-align', 'left')

                svg2.selectAll()
                    .data(this.clusters.xScale)
                    .enter()
                    .append('rect')
                        .attr('x', d =>  xScaleCluster(d) + MARGIN_CLUSTERS.left)
                        .attr('y', () => yScaleCluster())
                        .attr('rx', 0)
                        .attr('ry', 0)
                        .attr('width', xScaleCluster.bandwidth())
                        .attr('height', yScaleCluster.bandwidth())
                        .style('fill', (d,i) => colorCluster(this.clusters.colorScale[i]))
                        .style('stroke-width', 4)
                        .style('stroke', 'none')
                        .style('opacity', 1)
                    .on('mouseover', function () {
                        tooltipCluster.style('opacity', 1).style('display', 'block')
                    })
                    .on('mousemove', function (e, d) {
                        const innerHtml = '<b>Cluster</b>: ' + d.split('_')[0]

                        let clientJustifyX = e.clientX + window.pageXOffset + 10
                        let clientJusitfyY = e.clientY + window.pageYOffset

                        tooltipCluster.html(innerHtml)
                            .style('left', clientJustifyX + 'px')
                            .style('top', clientJusitfyY + 'px')
                    })
                    .on('mouseleave', function () {
                        tooltipCluster.style('opacity', 0).style('display', 'none')
                    })

            }

        }
    }
</script>

<style>

    #biomarkersDownload {
        text-align: right;
        margin-top: -1em;
        margin-bottom: 0.5em;
        padding-right: 1em;
    }

    #biomarkersDownload:hover {
        text-align: right;
        margin-top: -1em;
    }

</style>