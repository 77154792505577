<template>
    <div id="footer" class="row justify-center">
        <div class="col-12">
            <img :src="MoffittSVG" id="moffittLogo"/>
        </div>
    </div>
</template>

<script>

    import MoffittSVG from '../assets/svg/moffitt-logo.svg'

    export default {
        name: 'Footer',
        created () {
            this.MoffittSVG = MoffittSVG
        }
    }
</script>

<style>

    #footer {
        margin-top: 1em;
    }

    #moffittLogo {
        width: 50%;
    }

</style>