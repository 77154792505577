<template>
    <div :id="SCATTERPLOT_CONTAINER_ID + containerNumber" class="col-12">
        <span class="plotTitle">{{PLOT_TITLE}}</span>
        <div class="scatterplot" :id="SCATTERPLOT_BASE_ID + containerNumber"/>
    </div>
</template>

<script>

    import colorPalette50 from '../assets/json/colorPalette50.json'
    import {
        axisBottom,
        axisLeft,
        interpolateCool,
        interpolateWarm,
        max,
        min,
        scaleLinear,
        scaleOrdinal,
        scaleSequential,
        select,
        symbol,
        symbolCircle,
        symbolCross,
        symbolDiamond,
        symbolSquare,
        symbolStar,
        symbolTriangle,
        symbolWye
    } from 'd3'

    const CLUSTER_FIELDS = ['rb10_semi', 'rb10_unsv']
    const SCATTERPLOT_CONTAINER_ID = 'scatterPlotContainer'
    const SCATTERPLOT_BASE_ID = 'scatterplot_c'

    export default {
        name: 'ScatterPlot',
        props: {
            data: Object,
            paint: String,
            select: Object,
            containerNumber: String,
            plotTitle: String,
            unscaledRnaseqMaxExpressor: Number
        },
        data () {
            return {
                domainMap: []
            }
        },
        created () {
            this.SCATTERPLOT_CONTAINER_ID = SCATTERPLOT_CONTAINER_ID
            this.SCATTERPLOT_BASE_ID = SCATTERPLOT_BASE_ID
            this.PLOT_TITLE = this.plotTitle
        },
        updated () {
            this.createScatterPlot()
        },
        mounted () {
            this.createScatterPlot()
        },
        methods: {
            createScatterPlot () {

                // create a color scale for gene expression data if present
                if ('geneExpressionValue' in this.data[0]) {

                    const geneExpressionDomain = []
                    this.data.forEach(obj => {
                        geneExpressionDomain.push(obj.geneExpressionValue)
                    })

                    //var geneExpressionColorScale = scaleSequential().interpolator(interpolateYlGnBu)
                    var geneExpressionColorScale = scaleSequential().range(['#6495ed','#dc143c'])
                        .domain([min(geneExpressionDomain), max(geneExpressionDomain)])

                    //var geneExpressionColorScale = scaleSequential().interpolator(interpolateYlGnBu)
                    //    .domain([0, this.unscaledRnaseqMaxExpressor])

                }

                // set d3 plot dimensions
                const MARGIN = { top: 30, right: 60, bottom: -10, left: 60 }
                const WIDTH = 450 - MARGIN.left - MARGIN.right
                const HEIGHT = 450 - MARGIN.top - MARGIN.bottom

                const DEFAULT_FILL = '#69b3a2'
                const SYMBOL_SIZE_DEFAULT = 50
                const SYMBOL_SIZE_SELECTED = 115
                const SYMBOL_SIZE_DESELECTED = 25
                const TOOLTIP_WIDTH = 250
                const OPACITY_DEFAULT = 1
                const OPACITY_DESELECT = 0.5

                const xValues = []
                const yValues = []

                this.data.forEach(obj => {
                    xValues.push(obj.component_1)
                    yValues.push(obj.component_2)
                })

                // remove svg and recreate new
                if (document.getElementById(SCATTERPLOT_BASE_ID + this.containerNumber)) {
                    select('#' + SCATTERPLOT_BASE_ID + this.containerNumber).remove()
                    select('#' + SCATTERPLOT_CONTAINER_ID + this.containerNumber)
                        .append('div')
                        .attr('id', SCATTERPLOT_BASE_ID + this.containerNumber)
                        .attr('class', 'scatterplot')
                }

                // add svg to DOM
                const svg = select('#' + SCATTERPLOT_BASE_ID + this.containerNumber)
                    .append('svg')
                        .attr('preserveAspectRatio', 'xMinYMin meet')
                        .attr('viewBox', '25 0 380 500')
                    .append('g')
                        .attr('transform', 'translate(' + MARGIN.left + ',' + MARGIN.top + ')')

                // x-axis scale and create plot axis
                const xScale = scaleLinear()
                    .domain([min(xValues), max(xValues)])
                    .range([0, WIDTH])

                svg.append('g')
                    .attr('transform', 'translate(0, ' + HEIGHT + ')')
                    .style('stroke-width', '0.08em')
                    .style('font-size', '0.6em')
                    .call(axisBottom(xScale))

                // y-axis scale and create plot axis
                const yScale = scaleLinear()
                    .domain([min(yValues), max(yValues)])
                    .range([HEIGHT, 0])

                svg.append('g')
                    .style('stroke-width', '0.08em')
                    .style('font-size', '0.6em')
                    .call(axisLeft(yScale))

                // add tooltip
                const tooltip = select('#' + SCATTERPLOT_BASE_ID + this.containerNumber)
                    .append("div")
                    .style("opacity", 0)
                    .attr("class", "tooltip")
                    .style("background-color", "white")
                    .style("border", "solid")
                    .style("border-width", "1px")
                    .style("border-radius", "5px")
                    .style("padding", "10px")
                    .style('width', TOOLTIP_WIDTH + 'px')
                    .style('z-index', 10)
                    .style('position', 'absolute')
                    .style('font-size', '0.8em')
                    .style('text-align', 'left')

                let domain = []
                this.data.forEach(obj => {
                    domain.push(obj[this.paint])
                })

                let colorScale;
                const domainNumeric = this.convertToNumericDomain(domain)
                typeof domain[0] === 'number' || !isNaN(domain[0]) ?
                    colorScale = scaleSequential().interpolator(interpolateWarm).domain([min(domain), max(domain)]) :
                    CLUSTER_FIELDS.includes(this.paint) ?
                        colorScale = scaleSequential().interpolator(interpolateCool).domain([min(domainNumeric), max(domainNumeric)]) :
                        colorScale = scaleOrdinal(colorPalette50.palette).domain(domain)



                // add dots
                svg.append('g')
                    .selectAll('.point')
                    .data(this.data)
                    .enter()
                    .append('path')
                        .attr('class', 'point')
                        .attr('transform', d => {
                            return 'translate(' + xScale(d.component_1) + ', ' + yScale(d.component_2) + ')'
                        })
                        .attr('d', symbol().type(d => {
                                return this.getPlotSymbol(d.time)
                            }).size(d => {
                                if (this.select && d[this.select.group] === this.select.value) {
                                    return SYMBOL_SIZE_SELECTED
                                } else if (this.select && d[this.select.group] !== this.select.value) {
                                    return SYMBOL_SIZE_DESELECTED
                                } else {
                                    return SYMBOL_SIZE_DEFAULT
                                }
                            })
                        )
                        .style('fill', d => {
                            if (this.paint && !('geneExpressionValue' in d)) {
                                return colorScale(this.domainMap[d[this.paint]])
                            } else if ('geneExpressionValue' in d) {
                                return geneExpressionColorScale(d.geneExpressionValue)
                            }
                            return DEFAULT_FILL
                        })
                        .style('opacity', d => {
                            if (this.select && d[this.select.group] === this.select.value) {
                                return OPACITY_DEFAULT
                            } else if (this.select && d[this.select.group] !== this.select.value) {
                                return OPACITY_DESELECT
                            } else {
                                return OPACITY_DEFAULT
                            }
                        })
                    .on('mouseover', function () {
                        select(this).style('stroke-width', 2).style('stroke', '#506e9b')
                        tooltip.style('opacity', 1).style('display', 'block')
                    })
                    .on('mousemove', function (e, d) {
                        select(this).attr('r', Math.ceil(SYMBOL_SIZE_DEFAULT * 1.1))
                        const innerHtml = '<b>Sample</b>: ' + d.sample + '<br>'
                            + '<b>Patient</b>: ' + d.patient + '<br>'
                            + '<b>Barcode</b>: ' + d.barcode + '<br>'
                            + '<b>Specimen</b>: ' + d.specimen + '<br>'
                            + '<b>Time</b>: ' + d.time + '<br>'
                            + '<b>Site</b>: ' + d.site + '<br><br>'
                            //+ '<b>Leiden Group</b>: ' + d.pred_RBConfigurationVertexPartition_1_0 + '<br>'
                            + '<b>Component 1</b>: ' + d.component_1 + '<br>'
                            + '<b>Component 2</b>: ' + d.component_2 + '<br><br>'
                            + '<b>Avg Gene Expression</b>: ' + d.geneExpressionValue + '<br><br>'
                            + '<b>rb10_semi</b>: ' + d.rb10_semi + '<br>'
                            + '<b>rb10_unsv</b>: ' + d.rb10_unsv + '<br><br>'
                            //+ '<b>curated.main</b>: ' + d.curated_main + '<br>'
                            //+ '<b>curated.subtypes</b>: ' + d.curated_subtypes + '<br>'
                            //+ '<b>blueprint.main</b>: ' + d.blueprint_main + '<br>'
                            //+ '<b>blueprint.find</b>: ' + d.blueprint_fine + '<br>'
                            //+ '<b>hpca.main</b>: ' + d.hpca_main + '<br>'
                            //+ '<b>hpca.fine</b>: ' + d.hpca_fine + '<br>'
                            //+ '<b>dice.main</b>: ' + d.dice_main + '<br>'
                            //+ '<b>dice.fine</b>: ' + d.dice_fine + '<br>'
                            //+ '<b>monaco.main</b>: ' + d.monaco_main + '<br>'
                            //+ '<b>monaco.fine</b>: ' + d.monaco_fine + '<br>'
                            //+ '<b>novershtern.main</b>: ' + d.novershtern_main + '<br>'
                            //+ '<b>novershtern.fine</b>: ' + d.novershtern_fine + '<br>'

                        const vpWidth = document.documentElement.clientWidth
                        const vpHeight = document.documentElement.clientHeight

                        let clientJustifyX = e.clientX + window.pageXOffset + 20
                        let clientJustifyY = e.clientY + window.pageYOffset - 30

                        if (e.clientX > vpWidth - Math.ceil(TOOLTIP_WIDTH * 1.1)) {
                            clientJustifyX = e.clientX + window.pageXOffset - TOOLTIP_WIDTH - 20
                        }

                        if (e.clientY > vpHeight - Math.ceil(vpHeight * 0.45)) {
                            clientJustifyY = e.clientY + window.pageYOffset - 300
                        }

                        tooltip.html(innerHtml)
                            .style('left', clientJustifyX + 'px')
                            .style('top', clientJustifyY + 'px')
                    })
                    .on('mouseleave', function () {
                        select(this).attr('r', SYMBOL_SIZE_DEFAULT).style('stroke-width', 0)
                        tooltip
                            .style('opacity', 0)
                            .style('display', 'none')
                    })

            },

            getPlotSymbol(time) {
                switch (time) {
                    case 't0':
                        return symbolCircle

                    case 't1':
                        return symbolCross

                    case 't2':
                        return symbolTriangle

                    case 't3':
                        return symbolSquare

                    case 't4':
                        return symbolDiamond

                    case 't5':
                        return symbolStar

                    case 't6':
                        return symbolWye

                    default:
                        return symbolCircle
                }
            },

            convertToNumericDomain(domain) {

                const unique = domain.filter((val,index, self) => self.indexOf(val) === index)
                unique.sort()

                const domainNumeric = []
                let uniqueNumericMap = {}

                unique.forEach((v, i) => {
                    uniqueNumericMap = { ...uniqueNumericMap, [v]: i+1 }
                })

                domain.forEach(v => {
                    domainNumeric.push(uniqueNumericMap[v])
                })

                this.domainMap = uniqueNumericMap
                return domainNumeric
            }
        }
    }
</script>

<style>

    .scatterplot {
        width: 90%;
    }

    .plotTitle {
        font-weight: 600;
        font-size: 1.25em;
    }

</style>