class Rb10Converter {

    constructor () {
        this.RB10_SEMI = 'rb10_semi'
        this.RB10_UNSV = 'rb10_unsv'
        this.SEMISUPERVISED = 'semi-supervised'
        this.UNSUPERVISED = 'unsupervised'
    }

    rb10ToLong (val) {
        if (val === this.RB10_SEMI) return this.SEMISUPERVISED
        if (val === this.RB10_UNSV) return this.UNSUPERVISED
        return val
    }

    longToRb10 (val) {
        if (val === this.SEMISUPERVISED) return this.RB10_SEMI
        if (val === this.UNSUPERVISED) return this.RB10_UNSV
        return val
    }

}

export default new Rb10Converter()