<template>
    <div class="q-pa-md">
        <div class="q-gutter-y-md column" style="width: 66%">
            <q-file color="teal"  v-model="uploadedFile" label="Upload Gene List" accept=".txt" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="attach_file" />
                </template>
            </q-file>
        </div>
        <div v-if="missingGenes && missingGenes.length > 0">
            <span id="missingGeneNotice" @click="onClickMissingGenes">Click here to view missing genes</span>
        </div>
        <MissingGenes v-if="showMissingGenes" :missingGenes="missingGenes"/>
    </div>
</template>

<script>

    import MissingGenes from './MissingGenes'
    import HttpService from '../services/HttpService';
    import routes from '../assets/json/routes.json'

    export default {
        name: 'GeneSetUploader',
        components: {MissingGenes},
        emit: ['uploadedGeneListChange'],
        props: {
            dataset: String,
            uploadedGeneListOverride: Boolean
        },
        data () {
            return {
                genelist: [],
                missingGenes: [],
                uploadedFile: null,
                showMissingGenes: false
            }
        },
        watch: {
            genelist (genes) {
                this.$emit('uploadedGeneListChange', genes)
            },
            uploadedFile (file) {

                if (file !== null) {
                    let reader = new FileReader()
                    reader.readAsText(file)
                    reader.onload = (e) => {
                        const rows = e.target.result.split('\n')
                        const trimmedGenes = []
                        rows.forEach(gene => {
                            if (gene !== null && gene !== '' && gene !== undefined) {
                                trimmedGenes.push(gene.trim().toUpperCase())
                            }
                        })

                        HttpService.post(routes.server.api.root + routes.server.api.missingGeneListGet, { dataset: this.dataset, geneList: trimmedGenes })
                            .then(res => {
                                this.genelist = [...res.data.foundGenes]
                                this.missingGenes = [...res.data.missingGenes]
                            })

                    }
                }

            },
            uploadedGeneListOverride () {
                this.uploadedFile = null
            }
        },
        methods: {
            onClickMissingGenes () {
                this.showMissingGenes = !this.showMissingGenes
            }
        }

    }

</script>

<style>

    #missingGeneNotice {
        cursor: pointer;
    }

</style>








































