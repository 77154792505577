<template>
    <div>
        <div id="headerMain">
            <h6>Welcome</h6>
        </div>
        <p/>
        <div class="q-pa-md row justify-center">
            <div class="q-gutter-md col-4">
                <q-select v-model="selectedDataset" :options="datasets" label="Select Dataset:" />
            </div>
        </div>
        <p/>
        <div class="row justify-center">
            <q-btn color="white" text-color="black" label="Start" @click="onClickStart"/>
        </div>
        <p/>
        <div id="homeOrSpacer">
            OR
        </div>
        <p/>
        <div class="row justify-center">
            <q-btn color="white" text-color="black" label="Upload Dataset" @click="onClickUpload"/>
        </div>
    </div>
</template>

<script>

    import HttpService from '../services/HttpService'
    import routes from '../assets/json/routes.json'

    export default {
        name: 'Home',
        data () {
            return {
                datasets: [],
                selectedDataset: null,
            }
        },
        mounted () {
            HttpService.get(routes.server.api.root + routes.server.api.datasetsAllGet)
                .then(res => {
                    if (!res.data.err) {
                        const datasetsAry = []
                        res.data.docs.forEach(obj => {
                            datasetsAry.push(obj.dataset)
                        })
                        this.datasets = datasetsAry

                        if (res.data.docs && res.data.docs.length > 0) {
                            this.selectedDataset = res.data.docs[0].dataset
                        }
                    }
                });
        },
        methods: {
            onClickStart () {
                //this.$router.push({ path: '/explore', query: { dataset: this.selectedDataset }})
                this.$router.push({ name: 'Explore', params: { dataset: this.selectedDataset }})
            },
            onClickUpload () {
                this.$router.push({ name: 'Upload' })
            }
        }
    }
</script>

<style>

    #headerMain {
        text-align: center;
    }

    #homeOrSpacer {
        text-align: center;
        margin-top: 15vh;
        margin-bottom: 15vh;
    }

</style>