<template>
    <div class="q-pa-md">
        <div class="q-gutter-sm">
            <q-radio keep-color v-model="selection" :val="SELECTIONS.drug" color="teal" label="Drug Predictions" size="2.5em" />
            <q-radio keep-color v-model="selection" :val="SELECTIONS.pathways" color="pink" label="Pathways" size="2.5em" />
        </div>
    </div>
</template>

<script>

    const SELECTIONS = { drug: 'drug', pathways: 'pathways' }

    export default {
        name: 'DrugPathwaySelector',
        emits: ['drugPathwaySelectionChange'],
        created () {
            this.SELECTIONS = SELECTIONS
        },
        watch: {

            selection (nVal) {
                this.$emit('drugPathwaySelectionChange', nVal)
            }

        },
        data () {
            return {
                selection: SELECTIONS.drug
            }
        },
        
    }
</script>

<style>

</style>