<template>
  <div>
    <div v-if="isProduction === 'false'">
      <DevelopmentBanner/>
    </div>
    <Navbar/>
    <router-view/>
  </div>
</template>

<script>

  import DevelopmentBanner from './components/DevelopmentBanner'
  import Navbar from './components/Navbar'

  export default {
    name: 'App',
    components: {
      DevelopmentBanner,
      Navbar
    },
    data () {
      return {
        isProduction: true
      }
    },
    created () {
      this.isProduction = process.env.VUE_APP_PRODUCTION
    }
  }
</script>

<style>

</style>